import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const BaufinanzierungWichtigstenTipps = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Baufinanzierung – worauf muss ich achten?" showCalc={false} />
            <Article>
                <p>
                    Du träumst seit Langem davon, ein Haus zu bauen oder eine Wohnung zu kaufen, und bist nun endlich
                    kurz davor, diesen Traum zu verwirklichen – wären da nur nicht die Myriaden an
                    Finanzierungsmöglichkeiten, denen du dich schon bei der ersten Sondierung gegenüber siehst. Doch
                    keine Sorge: Wie du deine finanziellen Möglichkeiten im Blick behältst und die schier
                    unüberschaubare Zahl an Angeboten fundiert vergleichst, erklären wir dir im folgenden Beitrag.
                </p>
                <hr />

                <h2>Kauf oder Miete? – Die Grundsatzfrage</h2>
                <p>
                    Aber eins nach dem anderen. Bevor du dich ernsthaft mit dem Thema{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>{" "}
                    auseinandersetzt, gilt es, zwei grundlegende Fragen zu beantworten: Bist du erstens tatsächlich dazu
                    bereit, dich für den Immobilienkauf zu verschulden, oder vielleicht doch eher ein*e Freund*in der
                    Flexibilität, die das Mieten mit sich bringt? Was wäre zweitens für dich leistbar, wenn du deine
                    aktuelle bzw. eine potenzielle Monatsmiete in einen{" "}
                    <Link to="/dienstleistungen/kreditrechner/">Immobilienkredit</Link> investieren würdest?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Baufinanzierung mit Kredit.</h2>
                <p>
                    Entscheidest du dich für den Kauf, so besteht der nächste Schritt darin zu klären, ob du überhaupt
                    damit rechnen kannst, einen Kredit zu erhalten. Um als Kreditnehmer*in Frage zu kommen, solltest du
                    einen Nachweis über ein regelmäßiges Einkommen während der vergangenen zwölf Monate erbringen
                    können. Bist du als Selbständige*r tätig, dann ist es ratsam, bereits zum Nachweis von wenigstens
                    einer Steuererklärung imstande zu sein.
                </p>
                <p>
                    Zudem ist zu klären, wie hoch der Maximalbetrag an Eigenmitteln ist, den du aufwenden kannst. Zur
                    Zeit ist es üblich, zumindest 20% der Finanzierungskosten mit Eigenkapital zu bestreiten – das
                    entspricht in etwa den Nebenkosten des Immobilienkaufs. Verfügst du jedoch über Eigenmittel in der
                    Höhe von mindestens 30% des Kaufpreises und hast dazu noch ein ausreichend hohes Einkommen, dann
                    solltest du bei der Beantragung eines Kredits auf keine allzu großen Hindernisse stoßen.
                </p>
                <p>
                    Wir raten dir zudem, von deinem jährlichen kostenlosen Auskunftsrecht beim{" "}
                    <a href="https://www.ksv.at/" target="_blank" rel="noreferrer noopener">
                        Kreditschutzverband
                    </a>{" "}
                    (KSV) Gebrauch zu machen. Dieser sammelt Informationen über das Zahlungsverhalten von Privatpersonen
                    und stellt Kreditgeber*innen auf diese Weise eine Datengrundlage zur Einschätzung deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    bereit. Häufig kommt es leider dazu, dass ein Kredit aufgrund eines einzigen negativen Vermerks
                    verwehrt wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Angebot
                    </a>
                </p>
                <hr />

                <h2>Haushaltsrechnung: Welche Rate kann ich mir leisten?</h2>
                <p>
                    Um die monatliche Rate abschätzen zu können, die für dich maximal leistbar ist, gehst du
                    folgendermaßen vor: Ziehe von deinem monatlichen Einkommen deine Fixkosten sowie die
                    voraussichtlichen Betriebskosten der neuen Immobilie ab und du erhältst das für die monatliche
                    Rückzahlung frei verfügbare Einkommen.
                </p>
                <p>
                    Aber Vorsicht: Aus Gründen der Risikominimierung wird die Bank, bei der du den Kredit beantragst,
                    nicht von den momentan niedrigen Zinsen ausgehen. Banken rechnen bei der Vergabe von Krediten
                    vielmehr mit einem Zinssatz von beispielsweise 4 % pro Jahr, selbst wenn die Zinsen zum Zeitpunkt
                    des Vertragsschlusses weitaus niedriger sind. Entscheidest du dich für einen Kredit mit variablen
                    Zinsen oder einer nur vorübergehenden Fixverzinsung, so solltest du folglich darauf achten, dir die
                    monatliche Rate auch dann noch leisten zu können, wenn die Zinsen – unter Umständen deutlich –
                    steigen. Beachte hierbei, dass eine variable Verzinsung in Summe zwar meist günstiger ausfällt, eine
                    Fixverzinsung dir jedoch die größere Planungssicherheit bietet.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Kreditvergleich
                    </a>
                </p>
                <hr />

                <h2>Wer hilft mir beim Vergleich von Kreditangeboten?</h2>
                <p>
                    Ganz einfach: miracl! Ausgehend von deiner Haushaltsrechnung und den entsprechenden
                    Einkommensnachweisen holen wir passende Angebote von unterschiedlichen Kreditinstituten ein und
                    helfen dir dabei, aus den unterschiedlichen Kreditarten, den für dich passenden zu finden.
                    Anschließenden unterstützen wir dich dabei, einen Vertrag abzuschließen, mit dem du rundum zufrieden
                    bist.
                </p>
                <hr />

                <h2>Welche Kosten muss ich beachten?</h2>
                <p>
                    Vor dem Vertragsschluss solltest du dir einen detaillierten Überblick über sämtliche Kosten
                    verschaffen, die zusätzlich zum Kaufpreis anfallen. Hierzu zählen u.a. die Grunderwerbsteuer, die
                    Makler*innenprovision, die Gebühr für die Grundbucheintragung und das Honorar für den*die Notar*in,
                    der*die den Kaufvertrag erstellt. Genaue Informationen über die Höhe der jeweiligen Kosten findest
                    du in unserem Artikel über Kaufnebenkosten beim Immobilien- bzw. Grundstückserwerb. In diesem
                    Zusammenhang ist es zudem ratsam, sich darüber zu informieren, welche Gebühren im Falle einer
                    vorzeitigen Rückzahlung zu begleichen sind – etwa dann, wenn du dich für eine Umschuldung
                    entscheidest.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Ersteinschätzung anfordern
                </a>
                <hr />

                <h2>Wie gestaltet sich die Rückzahlung des Kredits?</h2>
                <p>
                    Wesentlich ist in diesem Zusammenhang, dass Immobilienkredite in Österreich üblicherweise nach dem
                    Modell des Annuitätendarlehens abbezahlt werden. Das bedeutet, dass die monatlichen Raten zwar
                    gleich bleiben, sich das Verhältnis von Zinsen und tatsächlicher Rückzahlungsrate (der{" "}
                    <strong>Tilgung</strong>) allerdings über die Laufzeit hinweg verändert: Während der Anteil der
                    Zinsen sinkt, steigt jener der Tilgung. Die Annuität kann mit folgender Formel berechnet werden:
                </p>
                <ul>
                    <li>Annuität = Zinsanteil + Tilgungsanteil</li>
                </ul>
                <p>
                    Zuletzt solltest du noch die Möglichkeit sogenannter <strong>Sondertilgungen</strong> beachten –
                    d.h. zusätzlicher Teilrückzahlungen, die du mit deiner Bank vereinbarst und die dir dabei helfen
                    können, die Tilgungsrate deines Kredits zu beeinflussen. Höchstens 10 000 Euro kannst du in
                    Österreich gebührenfrei in Form einer Sondertilgung zurückzahlen.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"baufinanzierung-die-wichtigsten-tipps"}></BreadcrumbList>
        </Layout>
    );
};

export default BaufinanzierungWichtigstenTipps;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.bauen.baufinanzierung-die-wichtigsten-tipps", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
